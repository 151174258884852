<template>
	<div
		id="login"
		class="container-sm flex flex-column align-center mx-auto py-60 px-85 background-white rounded shadow-lg"
		@keydown.enter="login"
	>
		<img
			class="mb-50"
			src="@/assets/terminal.svg"
			alt=""
		/>

		<input
			type="email"
			v-model="form.email"
			class="w-100 mb-20 border p-20 color-grey rounded shadow"
			placeholder="Teldupostur"
			@blur="$v.form.email.$touch()"
			:disabled="hasTwoFactor"
			autofocus
		/>

		<div
			class="error"
			v-if="form.email && $v.form.email.$dirty && $v.form.email.$invalid"
		>
			Vinaliga skriva ein teldubústað
		</div>

		<input
			type="password"
			v-model="form.password"
			class="w-100 mb-20 border p-20 color-grey rounded shadow"
			placeholder="Loyniorð"
			@blur="$v.form.password.$touch()"
			:disabled="hasTwoFactor"
		/>

		<div
			class="error"
			v-if="passwordWrongError"
		>
			Brúkaranavnið ella loyniorðið er skeivt
		</div>

		<div
			:style="{
				transition: '0.2s height ease-in-out',
				overflow: 'hidden',
				height: hasTwoFactor ? '79px' : '0px',
			}"
			class="w-100"
		>
			<input
				v-model="form.twoFactorCode"
				ref="two-factor-code"
				class="w-100 border p-20 mb-20 color-grey rounded shadow"
				placeholder="Einnýtisloynital"
				@blur="$v.form.twoFactorCode.$touch()"
			/>
		</div>

		<div
			class="error"
			v-if="twoFactorWrongError"
		>
			Einnýtisloynitalið er skeivt
		</div>

		<a
			href="#"
			:class="{ disabled: this.$v.form.$invalid }"
			class="btn hover:background-blue-600"
			@click.prevent="login"
		>
			Rita inn
		</a>

		<router-link
			:to="{ name: 'ForgotPassword' }"
			href="#"
			class="btn btn-white hover:color-grey-800"
			@click.prevent
		>
			Gloymt loyniorð
		</router-link>
	</div>
</template>

<script>
import axios from 'axios';
const { required, email, minLength, helpers } = require('vuelidate/lib/validators');

export default {
	name: 'Login',

	data() {
		return {
			loading: false,
			passwordWrongError: false,
			twoFactorWrongError: false,
			hasTwoFactor: false,

			form: {
				email: '',
				password: '',
				twoFactorCode: '',
			},
		};
	},

	validations: {
		form: {
			email: {
				required,
				email,
			},

			password: {
				minLength: minLength(3),
				required,
			},

			twoFactorCode: {
				code: helpers.regex('twoFactorCode', /^[0-9]{6}$/),
			},
		},
	},

	methods: {
		async login() {
			if (this.$v.form.$invalid) {
				return;
			}

			// Two-Factor
			if (this.hasTwoFactor) {
				return await this.twoFactor();
			}

			const loginDto = {
				email: this.form.email,
				password: this.form.password,
			};

			this.loading = true;

			return await axios
				.post(`${process.env.VUE_APP_TERMINAL_API_URL}/auth/login`, loginDto)
				.then(async (response) => {
					const { twoFactor, user, accessToken } = response.data;

					if (twoFactor) {
						this.hasTwoFactor = true;

						return;
					}

					localStorage.setItem('user', JSON.stringify(user));
					localStorage.setItem('access_token', accessToken);

					this.$router.push({ name: 'TwoFactor' });
				})
				.catch(() => {
					this.passwordWrongError = true;
					this.form.password = '';
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async twoFactor() {
			const twoFactorDto = {
				code: this.form.twoFactorCode,
				email: this.form.email,
				password: this.form.password,
			};

			return await axios
				.post(`${process.env.VUE_APP_TERMINAL_API_URL}/auth/2fa`, twoFactorDto)
				.then(async (response) => {
					const { user, accessToken } = response.data;

					localStorage.setItem('user', JSON.stringify(user));
					localStorage.setItem('access_token', accessToken);

					this.$router.push({ name: 'Customers' });
				})
				.catch((e) => {
					console.log(e);

					this.twoFactorWrongError = true;
					this.form.twoFactorCode = '';
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
